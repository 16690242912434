import React from "react";

import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appliedConfig,
} from "../config/commonConfig";
const AboutUs = () => {
  return (
    <section
      id="about"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
          appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-5 "
            : " ")
        }
      >
        <h2 className="text-9 fw-600 text-center">About Medical Assign Pros App</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          Leading professionals of today. Into the future of tomorrow!
        </p>
        <div className="row">
          <div className="col-lg-6 text-center">
            
            <img className="img-fluid img-thumbnail" src="images/about-app.jpg" alt="" />
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <h2 className="text-6 mb-3 mt-0">
             America's need for healthcare professionals
            </h2>
            <p>
            In response to the unprecedented challenges presented by the 2020 pandemic, 
            which highlighted an urgent need for healthcare services and the displacement 
            within the job market, we initiated our Healthcare Staffing Division at Accurate 
            Staffing. This decision emerged from a collective desire to contribute positively to
             the communities we cherish in North Carolina.


            </p>
            <p>
            With a rich heritage of meeting our clients' and candidates' needs, branching into healthcare staffing was a natural progression for us. We embraced the opportunity to provide immediate and precise staffing solutions for a range of healthcare positions, from nursing and physical therapy roles to various clinical and non-clinical positions within the healthcare sector.


            </p>

          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
