import React from 'react';

function TermsAndConditions() {
  return (
    <div id="terms" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms of Use</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body p-4">
            <p className="lead">
              Welcome to Medical Assign Professionals, LLC. By accessing our
              services, you agree to be bound by the following terms and
              conditions. Please read them carefully.
            </p>
            <h5 className="text-4">Terms of Use</h5>
            <p>
              Medical Assign Professionals, LLC provides staffing solutions and
              professional development opportunities for medical professionals,
              including but not limited to CNAs, CRNAs, LPNs, and RNs. Our
              services are designed to meet the specific needs of healthcare
              facilities and support the professional growth of our staff.
            </p>
            <h5 className="text-4">User Obligations</h5>
            <p>
              By using our services, you agree to provide accurate and current
              information about yourself and your qualifications. You are
              responsible for maintaining the confidentiality of any login
              information associated with our services and for all activities
              under your account.
            </p>
            <h5 className="text-4">Professional Conduct</h5>
            <p>
              All professionals placed by Medical Assign Professionals, LLC are
              expected to adhere to the highest standards of professional
              conduct and patient care. Failure to do so may result in
              termination of the placement.
            </p>
            <h5 className="text-4">Intellectual Property Rights</h5>
            <p>
              All content provided by Medical Assign Professionals, LLC,
              including texts, graphics, logos, and software, is the property of
              Medical Assign Professionals, LLC or its content suppliers and
              protected by United States and international copyright laws.
            </p>
            <h5 className="text-4">Privacy Policy</h5>
            <p>
              Quidam lisque persius interesset his et, in quot quidam
              persequeris vim, ad mea essent possim iriure. Lisque persius
              interesset his et, in quot quidam persequeris vim, ad mea essent
              possim iriure. lisque persius interesset his et, in quot quidam
              mea essent possim iriure.
            </p>
            <h5 className="text-4"> Limitation of Liability</h5>
            <p>
              Medical Assign Professionals, LLC will not be liable for any
              direct, indirect, incidental, consequential, or punitive damages
              resulting from the use of our services.
            </p>

            <h5 className="text-4">Governing Law</h5>
            <p>
              These Terms of Service shall be governed by and construed in
              accordance with the laws of the State of North Carolina.
            </p>
            <h5 className="text-4">Changes to Terms of Service</h5>
            <p>
              Medical Assign Professionals, LLC reserves the right to modify
              these terms at any time. Your continued use of the services
              constitutes your agreement to the new terms.
            </p>
            <h3 className="mb-3 mt-4">Privacy Policy</h3>
            <p>
              Your privacy is important to us. This policy explains how we
              collect, use, protect, and disclose your information.
            </p>

            <h5 className="text-4">Information Collection and Use</h5>
            <p>
              We collect information that you provide when you use our services,
              including personal information, professional qualifications, and
              employment history. This information is used to provide and
              improve our staffing solutions and professional development
              programs.
            </p>

            <h5 className="text-4">Information Sharing and Disclosure</h5>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information without your consent,
              except as required by law or as necessary to provide our services
              (e.g., sharing information with healthcare facilities for
              placement purposes).
            </p>

            <h5 className="text-4">Data Security</h5>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information. However, no method of transmission
              over the Internet or method of electronic storage is 100% secure.
            </p>

            <h5 className="text-4">Compliance with Laws</h5>
            <p>
              We will disclose your information where required by law or
              subpoena.
            </p>

            <h5 className="text-4">Changes to Privacy Policy</h5>
            <p>
              Medical Assign Professionals, LLC reserves the right to update
              this privacy policy at any time. We will notify you of any changes
              by posting the new policy on our website.
            </p>

            <h5 className="text-4">Contact Us</h5>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us.
            </p>

            <p>
              <strong>Note:</strong> This is a basic template and starting
              point. Legal documents like these need to be tailored to the
              specific operations of the business and comply with local laws and
              regulations. For Medical Assign Professionals, LLC, it's essential
              to consult with a legal expert, particularly one familiar with
              North Carolina laws, to ensure full legal compliance, including
              aspects related to healthcare staffing and privacy concerns
              specific to the medical field.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
