import React from "react";

import Slider from "react-slick";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

const Testimonials = () => {
  const reviews = [
    {
      name: "Kelish Patel",
      position: "Working at: Health Care Unified",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“Working at Medical Assign Professional offers an unparalleled experience, blending innovative technology with a supportive community to advance healthcare careers. ”",
    },
    {
      name: "Janey Cary",
      position: "Working at: Wheeler Health Care",
      src: "images/testimonial/client-sm-2.jpg",
      desc: "“Our mobile app serves as a career compass, guiding healthcare professionals through a myriad of opportunities, enabling them to tailor their path with ease and precision.”",
    },
    {
      name: "Becky Conway",
      position: "Working at: Wake Med",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“OThe app not only streamlines job searches but also offers personalized development resources, making professional growth a continuous and convenient journey.  ”",
    },
    {
      name: "Chris Toms",
      position: "Working at: Health Care Unified",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "“With Medical Assign Professional, individuals gain access to a wide network of top-tier healthcare facilities, fostering connections that propel their careers to new heights.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="testimonial"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">What Our Team Say’s</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          You want to know what we stand for well hear it directly from our staff members and medical professionals onsite.
        </p>
        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((review, index) => (
              <div
                className="item testimonial border rounded text-center p-4"
                key={index}
              >
                <span className="text-9 text-body-tertiary opacity-2">
                  <i className="fa fa-quote-left" />
                </span>
                <p>{review.desc}</p>
                <img
                  className="img-fluid d-inline-block w-auto rounded-circle shadow-sm border"
                  src={review.src}
                  alt=""
                />
                <strong className="d-block fw-500">{review.name}</strong>{" "}
                <span className="text-body-tertiary text-2">
                  {review.position}
                </span>{" "}
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
