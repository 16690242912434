import React from "react";

function PrivacyPolicy() {
  return (
    <div id="privacy" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Privacy Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
          <h3 className="mb-3 mt-4">Privacy Policy</h3>
            <p className="lead">
              Your privacy is important to us. This policy explains how we
              collect, use, protect, and disclose your information.
            </p>

            <h5 className="text-4">Information Collection and Use</h5>
            <p>
              We collect information that you provide when you use our services,
              including personal information, professional qualifications, and
              employment history. This information is used to provide and
              improve our staffing solutions and professional development
              programs.
            </p>

            <h5 className="text-4">Information Sharing and Disclosure</h5>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information without your consent,
              except as required by law or as necessary to provide our services
              (e.g., sharing information with healthcare facilities for
              placement purposes).
            </p>

            <h5 className="text-4">Data Security</h5>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information. However, no method of transmission
              over the Internet or method of electronic storage is 100% secure.
            </p>

            <h5 className="text-4">Compliance with Laws</h5>
            <p>
              We will disclose your information where required by law or
              subpoena.
            </p>

            <h5 className="text-4">Changes to Privacy Policy</h5>
            <p>
              Medical Assign Professionals, LLC reserves the right to update
              this privacy policy at any time. We will notify you of any changes
              by posting the new policy on our website.
            </p>

            <h5 className="text-4">Contact Us</h5>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us.
            </p>

            <p>
              <strong>Note:</strong> This is a basic template and starting
              point. Legal documents like these need to be tailored to the
              specific operations of the business and comply with local laws and
              regulations. For Medical Assign Professionals, LLC, it's essential
              to consult with a legal expert, particularly one familiar with
              North Carolina laws, to ensure full legal compliance, including
              aspects related to healthcare staffing and privacy concerns
              specific to the medical field.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
