// notificationService.js
import { toast, ToastOptions } from 'react-toastify';
type ToastType = 'info' | 'success' | 'warn' | 'error';

/**
 * Displays a toast notification.
 * @param {string} message - The message to be displayed in the toast.
 * @param {'info'|'success'|'warn'|'error'} type - The type of the toast notification.
 * @param {Object} [options] - Optional toastify configuration options.
 */
const notify = (message: string, type: ToastType= 'info', options: ToastOptions = {}) => {
  // Ensure the type is one of the expected toast methods
  const toastType: ToastType = ['info', 'success', 'warn', 'error'].includes(type) ? type  : 'info';

  // Call the toast method dynamically
  toast[toastType](message, {
    position: 'top-center',
    autoClose: 5000,
    toastId: 'vat-map',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options,
  });
};

export default notify;
