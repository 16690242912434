// location-service.ts
type GeocodeResponse = {
  // Define the structure according to your geocoding API response
  results: Array<{
    address_components: Array<{
      long_name: string;
      short_name: string;
      types: string[];
    }>;
  }>;
};
// Extending the return type to include city and zip
export type LocationInfo = {
  state: string | null;
  city: string | null;
  zip: string | null;
};
// Define an error type for more predictable error handling
enum LocationErrorType {
  RequestFailed = "Geocoding API request failed",
  NoResults = "No results found in geocoding response",
  ComponentsNotFound = "Location components not found in geocoding response",
  UnableToRetrieve = "Unable to retrieve your location",
}

// Adjust the return type to use the defined error type
export const getUserLocation = async (): Promise<[LocationInfo | null, LocationErrorType | null]> => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  try {
    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const { latitude, longitude } = position.coords;
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);

    if (!response.ok) {
      return [null, LocationErrorType.RequestFailed];
    }

    const data: GeocodeResponse = await response.json();

    if (data.results?.length === 0) {
      return [null, LocationErrorType.NoResults];
    }

    const locationInfo: LocationInfo = { state: null, city: null, zip: null };

    for (const component of data.results[0].address_components) {
      if (component.types.includes('administrative_area_level_1')) {
        locationInfo.state = component.long_name;
      } else if (component.types.includes('locality')) {
        locationInfo.city = component.long_name;
      } else if (component.types.includes('postal_code')) {
        locationInfo.zip = component.long_name;
      }
    }
    if (!locationInfo.state && !locationInfo.city && !locationInfo.zip) {
      return [null, LocationErrorType.ComponentsNotFound];
    }

    return [locationInfo, null];
  } catch (error) {
    console.error('Error retrieving location:', error);
    return [null, LocationErrorType.UnableToRetrieve];
  }
};
