import { Parallax } from "react-parallax";

function Subscribe() {
  return (
    <section className="hero-wrap ">
      <div className="hero-mask opacity-9 bg-primary" />

      <Parallax
        bgImage="images/intro-bg-3.jpg"
        bgImageAlt="Intro"
        strength={200}
      >
        <div className="hero-content section">
          <div className="container">
            <div className="justify-content-center text-center">
              <h2 className="text-9 fw-600 text-white text-center mb-3">
                Apply to Medical Assign Pros
              </h2>
              <hr className="heading-separator-line bg-white opacity-10 mx-auto" />
              <p className="lead text-center text-white mb-4">
                Enhance your career with a strong support team.
              </p>
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">
       
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
}

export default Subscribe;
