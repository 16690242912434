/**
 * Encrypts data using AES encryption.
 * @param {string} data - The data to encrypt.
 * @returns {string} The encrypted data.
 */

export const encryptString = async (data: string) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const enc = new TextEncoder();

  // First, encode the secretKey as Uint8Array
  const encodedKey = enc.encode(secretKey);

  // Hash the key to ensure it's 256 bits long
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedKey);

  // Now the hashed key is the correct length for AES-256
  const key = await crypto.subtle.importKey(
    'raw',
    hashBuffer, // Use the hashed key here
    { name: 'AES-CBC' },
    false,
    ['encrypt']
  );

  // Encode the data to be encrypted
  const encodedData = enc.encode(data);

  // Generate an IV
  const iv = crypto.getRandomValues(new Uint8Array(16));

  // Encrypt the data
  const encrypted = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv: iv,
    },
    key,
    encodedData
  );

  // Convert the encrypted data to a hex string
  const hexBuffer = Array.from(new Uint8Array(encrypted))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
  return {
    iv: Array.from(iv)
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''),
    encryptedData: hexBuffer,
  };
};

/**
 *  HELPER METHODS FROM AWS 
 * import { MultipartFormData, IFormFields } from '../interfaces/iFormFields';
import { randomUUID } from 'crypto';
// Import individual AWS service clients from v3 SDK
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager';
import { STSClient, GetCallerIdentityCommand } from '@aws-sdk/client-sts';
import {   SendTemplatedEmailCommand } from '@aws-sdk/client-ses';
import { sesClient } from '../libs/ses-config';

// AWS SDK v3 is modular, so we configure clients for each service separately
const secretsManagerClient = new SecretsManagerClient({ region: 'us-east-1' });
const stsClient = new STSClient({ region: 'us-east-1' });

export function createRandomId() {
  return randomUUID();
}

export const generateS3Key = (
  basePath: string = 'medassignDocuments/',
  firstName: string,
  lastName: string,
  nameFile: string
): string => {
  // Sanitize the first and last names to replace any non-alphanumeric characters with a dash
  const sanitizedFirstName = firstName
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-+$/g, '');
  const sanitizedLastName = lastName
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-+$/g, '');

  const fileExtension = nameFile.split('.').pop();
  const fileName = nameFile.substring(0, nameFile.lastIndexOf('.'));

  // Construct the key using the sanitized names, filename, and file extension
  return `${basePath}${sanitizedFirstName}-${sanitizedLastName}/${fileName}.${fileExtension}`;
};

export const mapFormDataToFormState = (
  formData: MultipartFormData
): IFormFields => {
  return Object.keys(formData).reduce<IFormFields>((acc, key) => {
    const value = formData[key];
    if (typeof value === 'string') {
      (acc as any)[key] = value;
    } else {
      (acc as any)[key] = {
        filename: value.filename,
        content: value.content,
        contentType: value.mimetype,
      };
    }
    return acc;
  }, {} as IFormFields); // Initialize the accumulator as an IFormFields object
};

export const getSecret = async (secretId: string, isPlainText = false) => {
  console.log(`Fetching AWS Secrets for ${secretId}`);
  try {
    // Using command pattern for service interaction
    const command = new GetSecretValueCommand({ SecretId: secretId });
    const { SecretString } = await secretsManagerClient.send(command);
    if (!SecretString)
      throw new Error(`There was an error fetching your secrets`);
    return {
      statusCode: 200,
      secManager: isPlainText ? SecretString : JSON.parse(SecretString),
    };
  } catch (err) {
    console.error(`There was an error fetching your secrets:`, err);
    throw err; // It's usually better to throw the error to let the caller handle it unless specific error handling is required here
  }
};

export const getAccountId = async () => {
  try {
    const command = new GetCallerIdentityCommand({});
    const { Account } = await stsClient.send(command);
    return Account; // Directly return the account ID
  } catch (err) {
    console.error(`There was an error fetching the account ID:`, err);
    throw err; // Similarly, throw the error for the caller to handle
  }
};

const createSendEmailCommand = (toAddress: string, fromAddress: string) => {
  const createSendTemplatedEmailCommand = (toAddress, fromAddress, templateName, templateData) => {
    return new SendTemplatedEmailCommand({
      Destination: {
        ToAddresses: [
          toAddress, // Primary recipient's email address
          // You can add more recipient email addresses here
        ],
        CcAddresses: [
          // Optional CC recipient email addresses
        ],
      },
      Source: fromAddress, // The sender's email address
      Template: templateName, // The name of the SES email template you want to use
      TemplateData: JSON.stringify(templateData), // Stringified JSON object with the data for placeholders in the template
      ReplyToAddresses: [
        // Optional reply-to email addresses
      ],
    });
  };
  
};

const run = async () => {
  const sendEmailCommand = createSendEmailCommand(
    'recipient@example.com',
    'sender@example.com'
  );

  try {
    return await sesClient.send(sendEmailCommand);
  } catch (e) {
    console.error('Failed to send email.');
    return e;
  }
};

// snippet-end:[ses.JavaScript.email.sendEmailV3]
export { run };
/* 
  * Encrypt data using AES-256-CBC
  * @param {string}
  * Decrypt data using AES-256-CBC
  * 
  * 
  * 
  * 
export const decryptString = (encryptedObject: iEncryptionResult, secretKey: Buffer): string => {
  // Convert the IV and encrypted data back from hex strings
  const iv = Buffer.from(encryptedObject.iv, 'hex');
  const encryptedData = Buffer.from(encryptedObject.encryptedData, 'hex');

  // Create a decipher
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(secretKey), iv);

  let decrypted = decipher.update(encryptedData);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();

  
};


// Initialize the DynamoDB Document Client
const ddbDocClient = DynamoDBDocumentClient.from(new DynamoDBClient({ region: 'us-east-1' }));

async function getEncryptedSSN(id) {
  const params = {
    TableName: "YourTableName",
    Key: { id: id },
  };

  try {
    const { Item } = await ddbDocClient.send(new GetCommand(params));
    return Item ? Item.ss_number : null;
  } catch (error) {
    console.error("Error retrieving encrypted SSN:", error);
    throw error;
  }
}

async function retrieveAndDecryptSSN(id, secretKey) {
  const encryptedSSN = await getEncryptedSSN(id);
  if (!encryptedSSN) {
    console.log("SSN not found for ID:", id);
    return null;
  }
  
  return decryptData(encryptedSSN, secretKey);
}

// Example usage
const secretKey = 'your-very-secure-key'; // The same key used for encryption
const itemId = 'item-id-123'; // The ID of the item you're retrieving

retrieveAndDecryptSSN(itemId, secretKey)
  .then(ssn => console.log("Decrypted SSN:", ssn))
  .catch(error => console.error("An error occurred:", error));
  

 */