
export const maskSSN = (value: string): string => {  
    const onlyNums = value.replace(/\D/g, ''); // Remove non-digits
    let formattedSSN = '';

    // Format SSN as XXX-XX-1234
    if (onlyNums.length <= 3) {
      formattedSSN = onlyNums;
    } else if (onlyNums.length <= 5) {
      formattedSSN = onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
    } else if (onlyNums.length <= 9) {
      formattedSSN = onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 5) + '-' + onlyNums.slice(5);
    }
    if(onlyNums.length === 9) {
        formattedSSN = '***-**-' + onlyNums.slice(-4);
    }
    return formattedSSN;
  };
  