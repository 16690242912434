// formSubmissionService.js
import axios from 'axios';
import { IFormFields } from '../interfaces/iform';

const submitForm = async (
  wafToken: string,
  formFields: IFormFields
): Promise<void> => {
  try {
    const formUrl = process.env.REACT_APP_FORM_URL || '';
    const formData = new FormData();
    Object.keys(formFields).forEach((key) => {
      const value = formFields[key as keyof IFormFields];
      if (Array.isArray(value)) {
        value.forEach((item) =>
          formData.append(
            key,
            item instanceof File
              ? item
              : typeof item === 'object'
              ? JSON.stringify(item)
              : item.toString() || ''
          )
        );
      } else {
        formData.append(
          key,
          value instanceof File
            ? value
            : typeof value === 'object' && value !== null
            ? JSON.stringify(value)
            : value?.toString() || ''
        );
      }
    });

    await axios.post(formUrl, formData); // No need to set headers manually
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};

const submitFormWithWafIntegration = async (
  formUrl: string,
  formData: FormData
) => {
  try {
    const response = await (window as any).AwsWafIntegration.fetch(formUrl, {
      method: 'POST',
      body: formData,
    });

    console.log('what data', response);
  } catch (error) {
    console.error(error);
  }
};

export { submitForm, submitFormWithWafIntegration };
