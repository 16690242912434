import { IFormFields } from '../interfaces/iform';

export class FormFields implements IFormFields {
  first_name = '';
  last_name = '';
  birth_date = '';
  ss_number = '';
  masked_ss_number = '';
  email = '';
  facility_location = '';
  resume = null;
  license = null;
  phone_number = '';
  gender = null;
  address = '';
  city = '';
  state = { name: '', abbr: '' };
  zipcode = '';
  position = '';
  nclive = null;

  constructor() {  }
}
