import { useState, useEffect, useRef } from 'react';
import { Form, Button, ProgressBar, Row, Col, Card } from 'react-bootstrap';
import { maskSSN } from './_libs';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderConfig, appliedConfig } from '../../config/commonConfig';
import states from '../../models/states';
import { FormFields } from '../../models/form';
import { submitForm } from '../../services/form-service';
import { getUserLocation } from '../../services/location-service';
import notify from '../../services/notification-service';
import Captcha from './_components/captcha';
import { encryptString } from '../../util';

const Career = () => {
  const [step, setStep] = useState(1);
  const [formState, setformState] = useState(new FormFields());
  const [validated, setValidated] = useState(false);
  const [wafToken, setWafToken] = useState('');
  const [isResumeValid, setIsResumeValid] = useState(true);
  const [isLicenseValid, setIsLicenseValid] = useState(true);

  const [sendtingMail, setSendingMail] = useState(false);
  useEffect(() => {
    const fetchUserState = async () => {
      const [userLocation, err] = await getUserLocation();
      if (err) {
        console.warn('ERROR: ', err);
      }
      if (userLocation) {
        const { state: userState, city, zip } = userLocation;
        const selectedState = states.find((state) => state.name === userState);

        setformState((prevState) => ({
          ...prevState,
          state: {
            ...prevState.state,
            ...selectedState,
          },
          city,
          zipcode: zip,
        }));
      }
    };
    fetchUserState();
  }, []);

  const formRef = useRef(null);

  const validateCurrentStep = () => {
    const form = formRef.current;
    // Assume validity initially
    let isValid = true;

    switch (step) {
      case 1:
        // Validate step 1 specific fields
        isValid = form.checkValidity();
        break;
      case 2:
        isValid = form.checkValidity();
        break;
      // Default case if no specific validation is required or if it's beyond the defined steps
      default:
        // You might want to handle an unexpected step value, log an error, or simply assume validity
        console.warn('Unexpected step value encountered in validation:', step);
        isValid = false; // Or false, depending on how you want to handle this case
    }

    return isValid;
  };

  const handleNext = (event) => {
    let fileValidation = true;
    event.preventDefault();
    if (step === 2) {
      fileValidation = isResumeValid;
    }
    if (validateCurrentStep() && fileValidation) {
      // Proceed to the next step if current step is valid
      setStep((currentStep) => currentStep + 1);
    } else {
      setValidated(true);
      event.stopPropagation();
    }
  };

  const handleSSNFocus = (event) => {
    // Check if the value is the placeholder or contains 'X'
    if (
      event.target.value === 'XXX-XX-XXXX' ||
      event.target.value.includes('X')
    ) {
      setformState((prevformState) => ({
        ...prevformState,
        masked_ss_number: '',
      }));
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    let file;

    // Update for SSN field
    if (name === 'ss_number') {
      const rawValue = value.replace(/-/g, '').slice(0, 9);
      const maskedValue = maskSSN(rawValue);
      setformState((prevformState) => ({
        ...prevformState,
        ss_number: rawValue, // Store raw SSN value
        masked_ss_number: maskedValue, // Store masked SSN value
      }));
    } else {
      if (type === 'file') {
        file = files[0];
        const isValid = file && file.size <= 100 * 1024;

        if (!isValid) {
          notify('File size must be 100KB or less.', 'warn', {
            autoClose: 10000,
          });
          event.target.value = '';
          file = null;
        }
        // Update the validity state based on input name
        if (name === 'resume') {
          setIsResumeValid(isValid);
        } else if (name === 'license') {
          setIsLicenseValid(isValid);
        }
      } else {
        file = value;
      }
      setformState((prevformState) => ({
        ...prevformState,
        [name]: file,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSendingMail(true);
    let secureFormState = { ...formState };

    if (secureFormState.ss_number) {
      secureFormState.ss_number = await encryptString(
        secureFormState.ss_number
      );
      console.log('Told you we encrypted your ssn:', secureFormState.ss_number);
    }
    // Use the service to submit the formew
    try {
      await submitForm(wafToken, secureFormState);
      setformState(new FormFields());
      setStep(1); // Optionally reset to the first step
      setWafToken(null);
      notify('Form Submitted Successfully we will call you soon...', 'success');
      setValidated(false);
      setSendingMail(false);
    } catch (error) {
      setSendingMail(false);
      notify(
        `Error: ${error.message} submitting form contact us directly at: 919.275.0018`,
        'error'
      );
    }
  };

  return (
    <section
      id="contact"
      className={
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? 'bg-light'
          : ''
      }>
      <div className="row g-0">
        <div className="col-md-9 col-lg-9">
          <div className="section px-4 px-sm-5 mx-lg-5">
            <h2 className="fw-600 text-center mb-3">Start Your Career</h2>
            <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
            <p className="lead text-center mb-2">
              Complete the form and we'll get back to you in less than 24 hours.
            </p>

            <Form
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
              ref={formRef}>
              <ProgressBar className="mb-5" now={(step / 3) * 100} />
              <Card>
                {step === 1 && (
                  <fieldset
                    className="d-flex flex-column mb-3"
                    disabled={step !== 1}>
                    <Card.Title className="align-self-center text-secondary fw-700 text-7 mt-4">
                      Personal Information
                    </Card.Title>

                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="3"
                          className="mb-3"
                          controlId="first_name_input">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            required
                            size="sm"
                            type="text"
                            name="first_name"
                            value={formState.first_name || ''}
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            First name is kind of required.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          className="mb-3"
                          controlId="last_name_input">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="last_name"
                            value={formState.last_name || ''}
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Last name required.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          className="mb-3"
                          controlId="phone_number_input">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            required
                            placeholder="123-456-7890"
                            maxLength={14}
                            pattern="(\d{3}-\d{3}-\d{4}|\d{10})"
                            size="sm"
                            type="tel"
                            name="phone_number"
                            value={formState.phone_number || ''}
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Phone exs: 123-456-7890 | 1234567890
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="gender_input">
                          <Form.Label>Gender</Form.Label>
                          <Form.Select
                            name="gender"
                            size="sm"
                            value={formState.gender || ''}
                            onChange={handleInputChange}>
                            <option>Choose...</option>
                            <option>Male</option>
                            <option>Female</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="3"
                          className="mb-3"
                          controlId="birth_date_input">
                          <Form.Label>DOB</Form.Label>
                          <Form.Control
                            required
                            size="sm"
                            type="date"
                            name="birth_date"
                            value={formState.birth_date || ''}
                            onChange={handleInputChange}
                            pattern="\d{2}/\d{2}/\d{4}"
                          />
                          <Form.Control.Feedback type="invalid">
                            DOB is required and format should be MM/DD/YYYY.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          className="mb-3"
                          controlId="ss_number_input">
                          <Form.Label>SSN </Form.Label>
                          <Form.Control
                            required
                            size="sm"
                            type="text"
                            name="ss_number"
                            maxLength={11}
                            value={formState.masked_ss_number}
                            onChange={handleInputChange}
                            onFocus={handleSSNFocus}
                          />
                          <Form.Text className="text-muted">
                            SSN will be encrypted.
                          </Form.Text>

                          <Form.Control.Feedback type="invalid">
                            SSN is definitely required.
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          className="mb-3"
                          controlId="email_input">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="email"
                            name="email"
                            placeholder="name@example.com"
                            value={formState.email}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          className="mb-3"
                          controlId="address_input">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            placeholder="1234 Main St"
                            name="address"
                            value={formState.address}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="city_input">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            name="city"
                            value={formState.city}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="state_input">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            name="state"
                            value={formState.state.abbr}
                            onChange={handleInputChange}
                            size="sm">
                            <option value={formState.state.abbr || ''}>
                              {formState.state.abbr || 'Choose...'}
                            </option>
                            {states.map((state, index) => (
                              <option key={index} value={state.abbr}>
                                {state.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="zipcode_input">
                          <Form.Label>Zipcode</Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            name="zipcode"
                            value={formState.zipcode}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </fieldset>
                )}

                {step === 2 && (
                  <fieldset
                    className="d-flex flex-column mb-3"
                    disabled={step !== 2}>
                    <Card.Title className="align-self-center text-secondary fw-700 text-7 mt-4">
                      Work Experience
                    </Card.Title>

                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          xs={12}
                          sm={6}
                          className="mb-3"
                          controlId="position_one_input">
                          <Form.Label>
                            What position are you looking for?
                          </Form.Label>
                          <Form.Select
                            className="mb-3"
                            required
                            name="position"
                            size="sm"
                            value={formState.position || ''}
                            onChange={handleInputChange}>
                            <option value="nochoose">Choose...</option>

                            <option value="CNA">
                              Certified Nursing Assistants (CNAs)
                            </option>
                            <option value="CRNA">
                              Certified Registered Nurse Anesthetists (CRNAs)
                            </option>
                            <option value="LPN">
                              Licensed Practical Nurses (LPNs)
                            </option>
                            <option value="RN">Registered Nurses (RNs)</option>
                            <option value="Other">Other</option>
                          </Form.Select>
                          <div>
                            <Form.Label>
                              Lived in NC for more than 5 years?
                            </Form.Label>
                            <div>
                              <Form.Check
                                inline
                                label="Yes"
                                name="nclive"
                                type="radio"
                                id="inline-nclive-yes"
                                value="yes"
                                checked={formState.nclive === 'yes'}
                                onChange={handleInputChange}
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="nclive"
                                type="radio"
                                id="inline-nclive-no"
                                value="no"
                                checked={formState.nclive === 'no'}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          xs={12}
                          sm={6}
                          className="mb-3"
                          controlId="facility_location_input">
                          <Form.Label>
                            What facility do you want to work at?
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="facility_location"
                            value={formState.facility_location}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          xs={12}
                          sm={6}
                          controlId="upload_resume_input"
                          className="mb-3">
                          <Form.Label>Upload Your Resume</Form.Label>
                          {!formState.resume ? (
                            <>
                              <Form.Control
                                required
                                size="sm"
                                type="file"
                                name="resume"
                                onChange={handleInputChange}
                                isInvalid={!isResumeValid}
                              />
                              <Form.Text className="text-muted">
                                File size must be 100KB or less.
                              </Form.Text>
                              <Form.Control.Feedback type="invalid">
                                So your going to get hired without a resume? Not
                                today!
                              </Form.Control.Feedback>
                            </>
                          ) : (
                            <div className="">
                              Selected file: {formState.resume.name}
                              <button
                                type="button"
                                className="btn btn-link btn-sm"
                                onClick={() =>
                                  setformState({ ...formState, resume: null })
                                }>
                                Change file
                              </button>
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          xs={12}
                          sm={6}
                          controlId="upload_license_input"
                          className="mb-3">
                          <Form.Label>Upload Your License</Form.Label>
                          {!formState.license ? (
                            <>
                              <Form.Control
                                size="sm"
                                type="file"
                                name="license"
                                onChange={handleInputChange}
                                isInvalid={!isLicenseValid}
                              />
                              <Form.Text className="text-muted">
                                File size must be 100KB or less.
                              </Form.Text>
                              <Form.Control.Feedback type="invalid">
                                Your file will not upload, size is to large 100kb or less.
                              </Form.Control.Feedback>
                            </>
                          ) : (
                            <div>
                              Selected file: {formState.license.name}
                              <button
                                type="button"
                                className="btn btn-link btn-sm"
                                onClick={() =>
                                  setformState({ ...formState, license: null })
                                }>
                                Change file
                              </button>
                            </div>
                          )}
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </fieldset>
                )}
                {step === 3 && (
                  <fieldset
                    className="d-flex flex-column mb-3"
                    disabled={step !== 3}>
                    <Card.Title className="align-self-center text-secondary fw-700 text-7 mt-4">
                      Almost There
                    </Card.Title>
                    <Card.Body>
                      <Captcha setWafToken={setWafToken} />
                    </Card.Body>
                  </fieldset>
                )}
                <div className="d-flex justify-content-between m-4">
                  {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious}>
                      Previous
                    </Button>
                  )}
                  {step < 3 ? (
                    <Button variant="primary" onClick={handleNext}>
                      Next
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit" disabled={!wafToken}>
                      {sendtingMail ? (
                        <>
                          <span className="me-2">Sending</span>
                          <i className="fas fa-certificate fa-spin"></i>
                        </>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  )}
                </div>
              </Card>
            </Form>
          </div>
        </div>
        <div className="col-md-3 col-lg-3 bg-light ">
          <section className="section px-4 px-sm-5 mx-lg-4">
            <h2 className="fw-600 mb-3">Our Headquarter</h2>
            <hr className="heading-separator-line bg-primary opacity-10" />
            <p className="lead">
              For Career opportunities in your area, Get in touch with us.
            </p>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                {' '}
                <i className="fas fa-map-marker-alt" />
              </div>
              <h3>Medical Assign Professionals, LLC.</h3>
              <p>
                555 Fayetteville Street
                <br />
                Suite 201
                <br />
                Raleigh, NC 27616
              </p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                <i className="fas fa-phone" />{' '}
              </div>
              <h3>Telephone</h3>
              <p className="mb-0">(+1) 919.386.9497</p>
              <p>(+1) 919.275.0018</p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                <i className="fas fa-envelope" />{' '}
              </div>
              <h3>Business Inquiries</h3>
              <p>info@medassignpros.com</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Career;
