import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "What is the Medical Assign Professionals App?",
      answer: "The mobile app bridges the gap between healthcare facilities and professionals, setting a new standard in fostering professional excellence and operational effectiveness in the medical sector.."
    },
    {
      question: "How do I apply for a career?",
      answer:"Download the mobile app and signup or fill out the career application on the website."    },
    {
      question: "Is my data safe with Medical Assign Professionals?",
      answer:
        "Your data is safeguarded with Medical Assign Professionals, adhering to HIPAA policies and secured on AWS cloud infrastructure.",
    },
    {
      question: "How soon can I start work after I graduate?",
      answer:
        "Generally, after completing necessary certifications and passing background checks, you could start work within a few weeks to a couple of months, depending on the demand and how quickly you navigate the hiring process..",
    },
    {
      question: "Will Medical Assign Professionals really pay for my certifications?",
      answer:
        "Yes, we cover 30% of your certification costs, provided you commit to working with us for up to 1 1/2 years.",
    },
  ];

  return (
    <section id="faq" className="section">
      <div className="container">
        <h2 className="text-9 fw-600 text-center">
          Frequently Asked Questions
        </h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          You can present your app FAQ here!
        </p>
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto">
            <hr className="mb-0 opacity-1" />
            <Accordion flush defaultActiveKey="0" className="arrow-end">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
            <hr className="mt-0 opacity-1" />
          </div>
        </div>
        <div className="lead text-center mt-4">
         Ready to get started?
          <a
            href="#contact"
            className="smooth-scroll link-offset-2 link-underline link-underline-opacity-0-hover px-2"
          >
            Your career awaits here.
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
