import React from 'react';
import { HeaderConfig, appliedConfig } from '../config/commonConfig';

function Features() {
  return (
    <section
      id="features"
      className={
        'section ' +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? 'px-lg-4 '
          : ' ')
      }>
      <div className="container">
        <h2 className="text-9 fw-600 text-center">
          Our <abbr className="mx-2" title="Medical Assign Professionals, LLC">MAP</abbr>
          Features
        </h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">
          Bringing quality professionals to the forefront. While enhacing their
          careers.
        </p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-notes-medical" />
                  </div>
                  <h3>Career Assistance </h3>
                  <p>
                    Our comprehensive application is designed meticulously to
                    navigate you towards the professional career you aspire to
                    achieve.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-money-check-alt" />
                  </div>
                  <h3>Incentived Education</h3>
                  <p>
                    We passionately motivate our team to excel, pushing boundaries to
                    become more proficient professionals in
                    the field.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-user-friends" />
                  </div>
                  <h3>Onsite Management</h3>
                  <p>
                    Experienced professionals collaboratively assist in the
                    seamless onboarding process for newcomers enhancing efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img
              alt=""
              src="images/app-showcase-feature.jpg"
              className="img-fluid img-thumbnail"
            />
          </div>
          <div className="col-lg-4 ">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-user-check" />
                  </div>
                  <h3>Credential Verification</h3>
                  <p>
                    Our  verification team works to
                    guarantee that all our staff's credentials are consistently
                    up-to-date and authenticated.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-hospital-user" />
                  </div>
                  <h3>Temporary Hire</h3>
                  <p>
                  Accurate Staffing's temp-to-hire services let employers assess fit, skills,
                   and work ethic risk-free.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-sync-alt" />
                  </div>
                  <h3>Employee Evaluations</h3>
                  <p>
                  Did our employees meet your expectations? Medical Assign Professionals seeks your feedback to enhance our service quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
