import React, { useEffect, useRef } from 'react';

const CAPTCHA_API_KEY = process.env.REACT_APP_CAPTCHA_API_KEY;
const Captcha = ({ setWafToken }) => {
  const captchaRef = useRef(null);

  // CAPTCHA success function
  const captchaSuccessFunction = (wafToken) => {
    setWafToken(wafToken);
  };

  // CAPTCHA error function
  const captchaErrorFunction = (error) => {
    console.error('CAPTCHA error:', error);
  };

  useEffect(() => {
    window.AwsWafCaptcha = window.AwsWafCaptcha || {};
    if (captchaRef.current) {
      window.AwsWafCaptcha.renderCaptcha(captchaRef.current, {
        apiKey: CAPTCHA_API_KEY,
        onSuccess: captchaSuccessFunction,
        onError: captchaErrorFunction,
        dynamicWidth: true,
        skipTitle: true,
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once upon mounting

  return <div ref={captchaRef}></div>;
};

 


export default Captcha;
