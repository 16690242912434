import { Tooltip as BsTooltip } from "bootstrap";
import { useEffect, useRef, cloneElement } from "react";

export const Tooltip = ({ text, placement, children }) => {
  const childRef = useRef(null);

  useEffect(() => {
    if (childRef.current) {
      const tooltipInstance = new BsTooltip(childRef.current, {
        title: text,
        placement: placement || 'top', // Default placement to 'top' if not provided
        trigger: "hover",
      });
      return () => tooltipInstance.dispose();
    }
  }, [text, placement]); // Include placement in the dependencies array

  return cloneElement(children, { ref: childRef });
};
